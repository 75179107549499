import $api from '../Http/index'

export default class MakeService{
    static async getMake(){
        return await $api.get(`/make`)
    }

    static async editMake(data){
        return await $api.patch(`/make`, {data})
    }
}