import React, {Component} from 'react';
import Control from "../Control/Control";
import Loader from "../Loader/Loader";
import AmoService from "../../services/AmoService";
import "./style.css"

export default class Widget extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            users: [],
            components: 'widget',
            save: false
        };
    }

    async componentDidMount() {
        try{
            const response = await AmoService.getUsers();
            if (response.data){
                this.setState({
                    users: response.data,
                    isLoading: false
                })
            }
        } catch (e) {
            this.setState({
                isLoading: false
            })
        }
    }

    copyLink = (e) => {
        navigator.clipboard.writeText(e.target.previousElementSibling.value)
        let el = document.querySelector('.copy.select');
        if (el){
            el.classList.remove('select');
            el.classList.add('non-select');
        }
        let next = e.target.nextElementSibling;
        if (next.classList.contains('non-select')){
            next.classList.remove('non-select');
            next.classList.add('select');
        }

    }

    render() {
        return (
            this.state.isLoading
                ? <Loader/> :
                <div className="page-container">
                    <Control
                        components={this.state.components}>
                    </Control>
                    <div className="container-inner">
                        <div className="widget-container">
                            <div className="warning"><i className="fa-solid fa-circle-exclamation"></i> Внимание! Не сообщайте ни кому эти адреса, иначе злоумышленники смогут получить доступ к вашей информации!</div>
                            <div className="widget-item">
                                <div className="header">Виджет для РОПа</div>
                                <div className="users-link">
                                    <input type="text" className="form-control url" value={`${window.location.origin}/widget?hash=${this.props.user.hash}&type=rop`} onChange={(e) =>{}}/>
                                    <button className="btn btn-success fa-solid fa-copy" onClick={this.copyLink}></button>
                                    <div className="copy non-select">Скопировано!</div>
                                </div>
                            </div>
                            {
                                this.state.users.length ?
                                    <div className="widget-item" >
                                        <div className="header">Виджет для Менеджера</div>
                                        {
                                            this.state.users.map(user => (
                                                <div className="users-link" key={`wd-${user.id}`}>
                                                    <div className="users-name">{user.name}</div>
                                                    <input type="text" className="form-control url" value={`${window.location.origin}/widget?hash=${this.props.user.hash}&type=user&id=${user.id}`} onChange={(e) =>{}}/>
                                                    <button className="btn btn-success fa-solid fa-copy" onClick={this.copyLink}></button>
                                                    <div className="copy non-select">Скопировано!</div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                    : null
                            }

                        </div>
                    </div>
                </div>

        );
    }
}
