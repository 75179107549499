import React from 'react';


const Button = (props) => {
    const openWindow = () => {
        let url = createUrl();
        console.log(url);
        const w = 600;
        const h = 700;
        const dual_screen_left = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
        const dual_screen_top = window.screenTop !== undefined ? window.screenTop : window.screen.top;

        const width = window.innerWidth
            ? window.innerWidth
            : (document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width);

        const height = window.innerHeight
            ? window.innerHeight
            : (document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height);

        const left = ((width / 2) - (w / 2)) + dual_screen_left;
        const top = ((height / 2) - (h / 2)) + dual_screen_top;

        const new_window = window.open(url, 'Авторизация через Google', 'scrollbars, status, resizable, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

        if (window.focus) {
            new_window.focus();
        }
        window.addEventListener('message', updateGoogleInfo);

        function updateGoogleInfo(e) {
            if (e.data){
                if (e.data.error !== undefined) {
                    alert(e.data.error);
                    window.removeEventListener('message', updateGoogleInfo);
                    new_window.close();
                } else if(e.data.google){
                    props.setGoogle(e.data.google);
                    window.removeEventListener('message', updateGoogleInfo);
                    new_window.close();
                }
            }
        }
    }

    const createUrl = () => {
        let url = ` https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?access_type=offline&state=${props.user.hash}&mode=post_message`;
        url += `&response_type=code&redirect_uri=https://incidents.7video.org/api/google/auth`;
        url += `&client_id=2481317561-khkjgrdfap8ssbhvt0f3gg2hlr1oh0tb.apps.googleusercontent.com`;
        url += `&scope=https://www.googleapis.com/auth/spreadsheets%20https://www.googleapis.com/auth/drive.readonly%20openid%20email%20profile&prompt=consent`;

        return url;
    }
    return (
        <button className='btn btn-success' onClick={openWindow}>Подключить</button>
    );
};

export default Button;