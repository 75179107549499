import $api from '../Http/index'

export default class FilterService{
    static async getFilter(components){
        return await $api.get(`/filter/${components}`)
    }

    static async setFilter(components, data){
        return await $api.post(`/filter/${components}`, {data})
    }
}