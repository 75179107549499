import React from 'react';
import Dot from "../Loader/Dot/Dot";


const Pipe = (props) => {

    const active = (id) => {

        if (props.userData === id) {
            return 'active';
        }
        return '';
    }

    return (
        <div className="conf-item">
            <div className="ci-header">{props.children}</div>
            {
                props.data ? <div className="users-select">
                    {
                        props.data.map(item => (

                            props.type && item.type !== props.type ? null :
                                <div key={`af-${item.id}`} className={`user-item change-item ${active(item.id)}`}
                                     data-id={item.id} data-name={props.name}
                                     onClick={props.cheng}>{item.name}</div>
                        ))
                    }
                </div> : null
            }
            {
                props.loading ? <Dot/> : null

            }
        </div>
    );
};

export default Pipe;