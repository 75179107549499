import React from 'react';
import "./style.css"

const Dot = (props) => {


    return (
        <div className="loader-container">
            <div className="dot-loader">
                <span className="dot"></span>
                <div className="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    );
};

export default Dot;