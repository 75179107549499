import React from 'react';
import BaseClass from "../Classes/BaseClass";
import AmoService from "../../services/AmoService";
import Delay from "./Table/Delay";
import Users from "./Items/Users";
import Pipe from "./Items/Pipe";
import One from "./Items/One";
import Dot from "./Loader/Dot/Dot";
import ReactDOM from "react-dom";
import Fields from "./Items/fields";


export default class Config extends BaseClass {

    constructor(props) {
        super(props);
        this.state = {
            crmData: {},
            userData: {},
            baseData: [],
            loading: true
        };
    }

    async componentDidMount() {
        await this.getConfig();
        this.setState({
            baseData: this.buildSelect(true)
        })

    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.save){
            this.props.setData(this.state.userData);
        }
    }

    getConfig = async () => {
        try{
            const response = await AmoService.getConfig();
            if (response.data){
                this.setState({
                    crmData: response.data?.crm,
                    userData: response.data?.user
                })
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    buildSelect = (base = false) => {
        let selectData = [];
        if (this.state.crmData?.pipelines){
            this.state.crmData.pipelines.map(items => {
                items?._embedded?.statuses?.map(item => {
                    if (this.state.userData?.delay && !base){
                        let check = this.state.userData.delay.findIndex(fields => fields.pipelines_id === items.id && fields.statuses_id === item.id);

                        if (check === -1){
                            selectData.push({value: item.id, pipe:items.id, label: items.name+' '+item.name});
                        }
                    } else {
                        selectData.push({value: item.id, pipe:items.id, label: items.name+' '+item.name});
                    }
                });
            });
        }
        return selectData
    }

    buildField = (base = false) => {
        let selectData = [];
        if (this.state.crmData?.fields){
            this.state.crmData.fields.map(item => {
                if (item?.enums?.length){
                    selectData.push({value: item.id, label: item.name, enums: item.enums});
                }
            });
        }
        return selectData
    }

    chengSolo = (e) => {
        let clone = this.state.userData;
        if (clone[e.target.dataset.name] && clone[e.target.dataset.name] === Number(e.target.dataset.id)){
            clone[e.target.dataset.name] = null;
        } else {
            clone[e.target.dataset.name] = Number(e.target.dataset.id);
        }

        this.setState({
            userData: clone
        })
    }

    chengUser = (e) => {
        let clone = this.state.userData;
        if (!clone[e.target.dataset.name]){
            clone[e.target.dataset.name] = [];
        }
        let index = clone[e.target.dataset.name].findIndex(item => item === Number(e.target.dataset.id));
        if (index !== -1){
            clone[e.target.dataset.name].splice(index, 1)
        } else {
            clone[e.target.dataset.name].push(Number(e.target.dataset.id));
        }

       this.setState({
           userData: clone
       })
    }

    chengStatuses = (e) => {
        let clone = this.state.userData;
        if (!clone[e.target.dataset.name]){
            clone[e.target.dataset.name] = {};
        }
        if (!clone[e.target.dataset.name][e.target.dataset.pipeline]){
            clone[e.target.dataset.name][e.target.dataset.pipeline] = [];
        }

        let index = clone[e.target.dataset.name][e.target.dataset.pipeline].findIndex(item => item === Number(e.target.dataset.id));
        if (index !== -1){
            clone[e.target.dataset.name][e.target.dataset.pipeline].splice(index, 1)
        } else {
            clone[e.target.dataset.name][e.target.dataset.pipeline].push(Number(e.target.dataset.id));
        }

        this.setState({
            userData: clone
        })
    }

    updateDelay = (data) =>{

       let clone = this.state.userData;
       for(const field in data.update){
           clone.delay[data.number][field] = data.update[field];
       }
       this.setState({
           userData: clone
       })

       return true;
    }

    addDelay = () =>{
        let clone = this.state.userData;
        if (!clone.delay){
            clone.delay = [];
        }
        clone.delay.push(
            {
                pipelines_id: null,
                statuses_id: null,
                delay: 0,
                reset: 0,
                disabled: 0
            }
        )

        this.setState({
            userData: clone
        })
    }

    addFields = () =>{
        let clone = this.state.userData;
        if (!clone.task){
            clone.task = {};
        }
        if (!clone.task.fields){
            clone.task.fields = [];
        }
        clone.task.fields.push(
            {
                field_id: null,
                field_value: null,
            }
        )

        this.setState({
            userData: clone
        })
    }

    deleteDelay = (index) => {
        let clone = this.state.userData;
        clone.delay.splice(index, 1);

        this.setState({
            userData: clone
        })
    }

    deleteTask = (index) => {

        let clone = this.state.userData;
        clone.task.fields.splice(index, 1);

        this.setState({
            userData: clone
        })
    }

    setTask = (value, field, index) => {
        let clone = this.state.userData;
        clone.task.fields[index][field] = value;

        this.setState({
            userData: clone
        })
    }

    start = (e) => {
        let clone = this.state.userData;
        clone[e.target.name] = Number(e.target.value);

        this.setState({
            userData: clone
        })
    }

    text = (e) => {
        let clone = this.state.userData;
        clone[e.target.name] = e.target.value;

        this.setState({
            userData: clone
        })
    }

    permissions = (index) => {
        return !this.state.userData.permission || this.state.userData.permission.includes(index);
    }


    render() {
        const selectData = this.buildSelect();
        const selectField = this.buildField();
        return (
            <div className='amo-login'>
                <div className="conf-item">
                    <div className="ci-header"><span className="red">Время запуска выгрузки отчета*</span></div>
                    {
                        this.state.loading ? <Dot/> : <div className="time-start">
                            <label htmlFor="hour-start">Часы от 0 до 23</label>
                            <input type="number" className='form-control' id='hour-start' onChange={this.start} value={this.state.userData?.start || ''} min={0} max={23} name='start'/>
                        </div>
                    }
                </div>

                <Users name='amo_user'
                       data={this.state.crmData?.users}
                       loading={this.state.loading}
                       userData={this.state.userData?.amo_user}
                       cheng={this.chengUser}>Пользователи срм</Users>

                {
                    this.permissions(3) ? <>
                        <div className="conf-item">
                            <div className="ci-header">Настройка задержки статусов</div>
                            {
                                this.state.loading ? <Dot/> :
                                    <table className='delay table'>
                                        <thead>
                                        <tr>
                                            <th>Статус <span className="red">*</span></th>
                                            <th className='time'>Время статуса<span className="red">*</span></th>
                                            <th className='time'>Время сброса</th>
                                            <th className='check'>Отключить исключение</th>
                                            <th className='action'>Действия</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.userData?.delay ?
                                                this.state.userData.delay.map((item, index) => (
                                                    <Delay key={`delay-${index}`} item={item} index={index} select={selectData} baseData={this.state.baseData} updateDelay={this.updateDelay} delete={this.deleteDelay}/>
                                                )) : null
                                        }
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colSpan={4}></td>
                                            <td className='text-end add-button'><button className='btn btn-success' onClick={this.addDelay}>Добавить</button></td>
                                        </tr>

                                        </tfoot>
                                    </table>
                            }

                        </div>

                        <One name='amo_field'
                             data={this.state.crmData?.fields}
                             userData={this.state.userData?.amo_field}
                             loading={this.state.loading}
                             type='checkbox'
                             cheng={this.chengSolo}>Поле отключающее проверку инцидента задержка статуса</One>
                    </> : null
                }

                {
                    this.permissions(2) ?
                        <Pipe name='amo_statuses_another'
                              data={this.state.crmData?.pipelines}
                              loading={this.state.loading}
                              userData={this.state.userData?.amo_statuses_another}
                              cheng={this.chengStatuses}>Статусы для проверки задачи на другого</Pipe> : null

                }

                {
                    this.permissions(1) ?
                        <Pipe name='amo_statuses_notask'
                              data={this.state.crmData?.pipelines}
                              loading={this.state.loading}
                              userData={this.state.userData?.amo_statuses_notask}
                              cheng={this.chengStatuses}>Статусы для проверки сделок без задачи</Pipe> : null
                }

                {
                    this.permissions(5) ?
                        <>
                            <Pipe name='amo_statuses_active_task'
                                  data={this.state.crmData?.pipelines}
                                  loading={this.state.loading}
                                  userData={this.state.userData?.amo_statuses_active_task}
                                  cheng={this.chengStatuses}>Статусы для проверки нет задачи до 30 дней</Pipe>

                            <One name='amo_active'
                                 data={this.state.crmData?.fields}
                                 userData={this.state.userData?.amo_active}
                                 loading={this.state.loading}
                                 type='checkbox'
                                 cheng={this.chengSolo}>Поле отключающее проверку инцидента нет задачи до 30 дней</One>
                        </> : null
                }

                {
                    this.permissions(7) ?
                        <div className="conf-item">
                            <div className="ci-header">Задержка перед проверкой перевода сделки без звонка/коммента</div>
                            {
                                this.state.loading ? <Dot/> : <div className="time-start">
                                    <label htmlFor="hour-start">В секундах (5мин = 300с)</label>
                                    <input type="number" className='form-control' id='delay-time' onChange={this.start} value={this.state.userData?.delay_time || ''} name='delay_time'/>
                                </div>
                            }
                        </div> : null
                }

                {
                    this.permissions(6) ? <>
                        <One name='type_budget'
                             data={this.state.crmData?.type}
                             userData={this.state.userData?.type_budget}
                             loading={this.state.loading}
                             cheng={this.chengSolo}>Тип инцидента смены бюджета</One>

                        <Pipe name='amo_statuses_budget'
                              data={this.state.crmData?.pipelines}
                              loading={this.state.loading}
                              userData={this.state.userData?.amo_statuses_budget}
                              cheng={this.chengStatuses}>Статусы для проверки смены бюджета</Pipe>
                    </> : null
                }

                {
                    this.permissions(5) ? <>
                        <One name='amo_date'
                             data={this.state.crmData?.fields}
                             userData={this.state.userData?.amo_date}
                             loading={this.state.loading}
                             type='date'
                             cheng={this.chengSolo}>Поле проверки даты доставки</One>

                        <Pipe name='amo_statuses_date'
                              data={this.state.crmData?.pipelines}
                              loading={this.state.loading}
                              userData={this.state.userData?.amo_statuses_date}
                              cheng={this.chengStatuses}>Статусы для проверки просроченной даты</Pipe>
                    </> : null
                }

                {
                    this.permissions(8) || this.permissions(9) ?
                        <>
                            <div className="conf-item">
                                <div className="ci-header">Фраза для поиска кп в примечании</div>
                                {
                                    this.state.loading ? <Dot/> : <div className="text-field">
                                        <input type="text" className='form-control' onChange={this.text} value={this.state.userData?.phrase_kp || ''} name='phrase_kp'/>
                                    </div>
                                }
                            </div>
                            <Pipe name='statuses_kp'
                                  data={this.state.crmData?.pipelines}
                                  loading={this.state.loading}
                                  userData={this.state.userData?.statuses_kp}
                                  cheng={this.chengStatuses}>Статусы для проверки КП
                            </Pipe>
                        </>
                     : null
                }

                {
                    this.permissions(10) ?
                        <>
                            <div className="conf-item">
                                <div className="ci-header">Установить задачу</div>

                                {
                                    this.state.userData?.task ?
                                        this.state.userData.task.fields.map((item, index) => (
                                            <React.Fragment key={`task-${index}`}>
                                                <Fields item={item} select={selectField} index={index} delete={this.deleteTask} change={this.setTask}/>
                                            </React.Fragment>
                                        )) : null
                                }

                               <button className='btn btn-success' onClick={this.addFields}>Добавить</button>
                            </div>
                            <div className="ci-header">Установить задачу каждые, дней</div>
                            {
                                this.state.loading ? <Dot/> : <div className="text-field">
                                    <input type="number" className='form-control' onChange={this.start} value={this.state.userData?.task_day || ''} name='task_day'/>
                                </div>
                            }

                            <div className="ci-header">Текст задачи</div>

                            {
                                this.state.loading ? <Dot/> : <div className="text-field">
                                    <input type="text" className='form-control' onChange={this.text} value={this.state.userData?.task_text || ''} name='task_text'/>
                                </div>
                            }

                            <Pipe name='statuses_task'
                                  data={this.state.crmData?.pipelines}
                                  loading={this.state.loading}
                                  userData={this.state.userData?.statuses_task}
                                  cheng={this.chengStatuses}>Статусы для проверки задач
                            </Pipe>
                        </>
                        : null
                }




            </div>
        );
    }
}
