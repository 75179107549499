import $api from '../Http/index'

export default class UserService{
    static async getUsers(){
        return await $api.get('/users')
    }

    static async addUser(data){
        return await $api.put('/users', {data:data})
    }

    static async updateUser(data){
        return await $api.patch('/users', {data:data} )
    }

    static async editUser(data){
        return await $api.patch('/user', {data:data} )
    }

    static async deleteUser(id){
        return await $api.delete('/users', { data: {id: id} });
    }
}