import React, {Component} from 'react';
import Loader from '../Loader/Loader';
import {field, modalButton, error} from "../RU";
import './edit.css'

export default class Edit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {

        if (this.props.user) {
            Object.keys(this.props.user).map(item => {
                this.setState({
                    [item]: this.props.user[item]
                });
            });
            this.setState({
                isLoading: false
            });
        }
    }

    handleChangeText = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = (event) => {
        this.props.updateUser(this.state);
        this.setState({
            password: ''
        });
        event.preventDefault();
    };


    render() {
        return (
            this.state.isLoading
                ? <Loader/> :
                <div className="page-container">
                    <div className='product-control fixed-top-menu'>
                        <div className="component-name">Настройки пользователя</div>
                    </div>
                    <div className="container-inner">
                        <div className="user-form">
                            <form onSubmit={this.handleSubmit}>
                                <table className="user-form-table">
                                    <tbody>
                                    <tr>
                                        <td rowSpan='7' className="user-edit-photo">
                                            <img src={this.state.photo ? `/photo/${this.state.photo}` : '/resources/avatar-none.png'} alt=""/>

                                        </td>
                                        <td className='user-label'>
                                            <div className="form-group-label"><label>{field.users.name}<span className="error-field">*</span></label></div>
                                        </td>
                                        <td className='user-input'>
                                            <div className="form-group"><input className="form-control" type="text"
                                                                               name='name' value={this.state.name || ''}
                                                                               onChange={this.handleChangeText}/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='user-label'>
                                            <div className="form-group-label"><label>{field.users.email}</label></div>
                                        </td>
                                        <td className='user-input'>
                                            <div className="form-group"><input className="form-control" type="text" disabled={true} name='e_mail' value={this.state.email}/></div>
                                        </td>
                                        <td className='user-error'></td>
                                    </tr>
                                    <tr>
                                        <td className='user-label'>
                                            <div className="form-group-label"> <label>{field.users.password}</label></div>
                                        </td>
                                        <td className='user-input'>
                                            <div className="form-group"><input className="form-control" type="password" name='password'
                                                                               value={this.state.password || ''} onChange={this.handleChangeText}/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <button type="submit"
                                                    className="btn btn-primary float-right">{modalButton.save}</button>
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
        );
    }
}
