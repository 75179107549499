import React from 'react';
import {
  NavLink
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUsers } from '@fortawesome/free-solid-svg-icons'

import {sidebar} from "./RU"

const Sidebar = (props) => {

    return(
        <ul className="sidebar-menu">

            <li className="treeview">
                <NavLink activeClassName="active" to={`/`}><div className='nav-button'><i className='fa fa-home'></i><br/>Главная</div></NavLink>
            </li>
            <li className="treeview">
                <NavLink activeClassName="active" to={`/conf`}><div className='nav-button'><i className='fa fa-gears'></i><br/>{sidebar.home}</div></NavLink>
            </li>
            <li className="treeview">
                <NavLink activeClassName="active" to={`/sort`}><div className='nav-button'><i className="fa fa-arrow-up-a-z"></i><br/>{sidebar.sort}</div></NavLink>
            </li>
            <li className="treeview">
                <NavLink activeClassName="active" to={`/make`}><div className='nav-button'> <i className="fa fa-question"></i><br/>{sidebar.make}</div></NavLink>
            </li>

            {
                props.admin ? <li className="treeview">
                    <NavLink activeClassName="active" to={`/users`}><div className='nav-button'><i className='fa fa-users'></i><br/>{sidebar.users}</div></NavLink>
                </li> : null
            }

        </ul>
    )
};

export default Sidebar;
