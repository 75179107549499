import $api from '../Http/index'

export default class GoogleService{

    static async check(){
        return await $api.get('/google/check')
    }

    static async exit(){
        return await $api.post('/google/exit')
    }

}