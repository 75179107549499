import React, {Component} from 'react';
import Control from "../Control/Control";
import Loader from "../Loader/Loader";
import SortService from "../../services/SortService";
import "./style.css"
import SortableList from './SortableList';
import ShowAlert from "../CoreAction/Notifications";

export default class Sort extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            users: [],
            components: 'sort',
            save: false
        };
    }

    async componentDidMount() {
        try{
            const response = await SortService.getSort();
            if (response.data){
                this.setState({
                    sort: response.data,
                    isLoading: false
                })
            }
        } catch (e) {
            this.setState({
                isLoading: false
            })
        }
    }

    arrayMove = (items, oldIndex, newIndex) => {

        let el = items[oldIndex];
        items.splice(oldIndex, 1);
        items.splice(newIndex, 0, el);
        return items;
    }

    onSortEnd = ({oldIndex, newIndex}) => {

        this.setState(({sort}) => ({
            items: this.arrayMove(sort, oldIndex, newIndex)
        }));
    }

    save = async (e) => {
        let data = this.state.sort.map(item => item.index);
        try{
            let response = await SortService.setSort(data);
            if (response.data){
                ShowAlert('success', 'save', this.state.components);
            }
        } catch (e) {
            ShowAlert('error', 'error', this.state.components);
        }
    }

    render() {
        return (
            this.state.isLoading
                ? <Loader/> :
                <div className="page-container">
                    <Control
                        components={this.state.components}>
                        <button className="btn btn-success" onClick={this.save}>Сохранить</button>
                    </Control>
                    <div className="container-inner">

                        <SortableList helperClass='sort-element' items={this.state.sort} onSortEnd={this.onSortEnd} />
                    </div>
                </div>

        );
    }
}
