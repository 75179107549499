import React from 'react';
import Select from "react-select";
import Date from "../Elements/Date";

const Delay = (props) => {

    const pipeChange = (newValue, actionMeta) => {
        let result = {
            update:{
                pipelines_id: newValue.pipe,
                statuses_id: newValue.value,
            },
            number: props.index
        };

        props.updateDelay(result);
    }

    const updateCore = (name, value) => {

        props.item[name] = value;
    }

    const handleChange = (e) => {
        let data = {number: props.index, update:{}};
        if (e.target.checked){
            data.update[e.target.name] = 1;
        } else {
            data.update[e.target.name] = 0;
        }
        props.updateDelay(data);
    }



    return (

        <tr>
            <td>
                <Select
                    isSearchable
                    cacheOptions
                    isClearablepipeChange
                    placeholder='Выбрать'
                    name='statuses'
                    onChange={pipeChange}
                    value={props.item.statuses_id ? props.baseData.find(option => option.value === props.item.statuses_id) : null }
                    options={props.select}
                />
            </td>
            <td>
                <Date time={props.item.delay} updateCore={updateCore} name='delay'/>
            </td>
            <td>
                <Date time={props.item.reset} updateCore={updateCore} name='reset'/>
            </td>
            <td className='check'>
                <input type="checkbox" name='disabled' id='disabled' checked={props.item.disabled} onChange={handleChange}/>
            </td>
            <td className='action'>
                <button className='btn btn-danger' onClick={(e) => props.delete(props.index)}>
                    <i className='fa fa-trash-alt'></i>
                </button>
            </td>
        </tr>
    );
};

export default Delay;