import $api from '../Http/index'

export default class AuthService {
    static async login(login, password){
        return await $api.post('/login', {login: login, password: password})
    }

    static async logout(){
        return await $api.post('/logout')
    }
}