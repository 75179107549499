import $api from '../Http/index'

export default class SortService{
    static async getSort(){
        return await $api.get(`/sort`)
    }

    static async setSort(data){
        return await $api.patch(`/sort`, {data})
    }
}