import React from 'react';
import Loader from '../Loader/Loader';
import BaseClass from "../Classes/BaseClass";
import Control from "../Control/Control";
import Amo from "../Amo/Amo";
import AmoService from "../../services/AmoService";
import GoogleService from "../../services/GoogleService";
import ShowAlert from "../CoreAction/Notifications";

export default class Home extends BaseClass {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            amo: {},
            google: {},
            components: 'home',
            save: false
        };
    }

    async componentDidMount() {
        await this.checkAmo();
        await this.checkGoogle();
        this.setState({
            isLoading: false
        })
    }

    checkAmo = async () => {
        try{
            const response = await AmoService.check();
            if (response.data){
               this.setAmo(response.data);
            }
        } catch (e) {
            console.log(e)
        }
    }

    checkGoogle = async () => {
        try{
            const response = await GoogleService.check();
            if (response.data){
                this.setGoogle(response.data);
            }
        } catch (e) {
            console.log(e)
        }
    }

    exit = async (type) => {
        if (type === 'google'){
            await this.exitGoogle();
        }

        if (type === 'amo'){
            await this.exitAmo();
        }
    }

    exitGoogle = async () => {
        try{
            const response = await GoogleService.exit();
            if (response.data){
                this.setGoogle({});
            }
        } catch (e) {
            console.log(e)
        }
    }

    exitAmo = async () => {
        try{
            const response = await AmoService.exit();
            if (response.data){
                this.setAmo(response.data);
            }
        } catch (e) {
            console.log(e)
        }
    }

    setData = async (data) => {
        try{
            const response = await AmoService.setData(data);
            if (response.data){
                ShowAlert('success', 'save', this.state.components);
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({
                save: false
            })
        }
    }

    setAmo = (amo) => {
        this.setState({
            amo: amo
        })
    }

    setGoogle = (google) => {
        this.setState({
            google: google
        })
    }

    save = () => {
        this.setState({
            save: true
        })
    }


    render() {
        return (
            this.state.isLoading
                ? <Loader/> :
                <div className="page-container">
                    <Control
                        addClass={` line-table home-table`}
                        components={this.state.components}>
                        <button className="btn btn-primary" disabled={this.state.save} onClick={this.save}>Сохранить</button>
                    </Control>
                    <div className="container-inner">
                        <Amo amo={this.state.amo} google={this.state.google} user={this.props.user} exit={this.exit} setAmo={this.setAmo} setGoogle={this.setGoogle} setData={this.setData} save={this.state.save}/>
                    </div>
                </div>

        );
    }
}
