import React from 'react';
import {field, modalButton} from "../../RU";
import ErrorField from "../../Core/ErrorField";
import AddClass from "../../Classes/AddClass";
import Select from "react-select";


export default class AddItem extends AddClass {
    constructor(props, state) {
        super(props, state);
        this.form = React.createRef();
    }

    getPermissions = (data) => {
        let result = [];
        let select = [];
        data.map((item, index) => {
            result.push({value:index , label: item});
            if (this.state?.permissions?.length && this.state.permissions.includes(index)){
                select.push({value: index, label: item});
            }
        });

        return {result, select};
    };

    render() {
        const permissions = this.getPermissions(this.props.incidents);
        return (
            <form onSubmit={(e) => this.handleSubmit(e, ['name'], ['id', 'name', 'email', 'is_admin','is_active', 'password', 'permissions']) }>
                <div className="modal-body">
                    <div className="form-group">
                        <label htmlFor='name'>{field.users.name}<span style={{color: 'red'}}>*</span></label>
                        <input className="form-control" type="text" name='name' id='name' value={this.state.name || ''}
                               onChange={this.handleChangeText}/>
                        {
                            this.props.form_error.name ?
                                <ErrorField error={this.props.form_error.name} component='users'/>
                             : null

                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor='email'>{field.users.email}</label>
                        <input className="form-control" type="text" name='email' id='email' value={this.state.email || ''}
                               onChange={this.handleChangeText}/>
                        {
                            this.props.form_error.email ?
                                <ErrorField error={this.props.form_error.email} component='users'/>
                                : null

                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor='password'>{field.users.password}</label>
                        <input className="form-control" type="password" name='password' id='password' autoComplete='off' value={this.state.password || ''}
                               onChange={this.handleChangeText}/>
                        {
                            this.props.form_error.password ?
                                <ErrorField error={this.props.form_error.password} component='users'/>
                                : null

                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor='password'>{field.users.permissions}</label>
                        <Select
                            value={permissions.select ?? []}
                            isSearchable
                            isMulti
                            isClearable
                            closeMenuOnSelect={false}
                            placeholder='Выбрать'
                            name='permissions'
                            onChange={this.onChangeMulti}
                            options={permissions.result}
                        />
                    </div>

                    <div className="form-group">
                        <input type="checkbox" name='is_admin' id='is_admin' checked={this.state.is_admin || 0}
                               onChange={this.handleChangeText}/>
                        <label className='checkbox' htmlFor='is_admin'>{field.users.is_admin}</label>
                    </div>
                    <div className="form-group">
                        <input type="checkbox" name='is_active' id='is_active' checked={this.state.is_active || 0}
                               onChange={this.handleChangeText}/>
                        <label className='checkbox' htmlFor='is_active'>{field.users.is_active}</label>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type='button' className="btn btn-secondary" onClick={this.props.handleModal}>{modalButton.close}</button>
                    <button type="submit" ref={this.form} disabled={this.state.disabled}
                            className="btn btn-primary">{this.state.id ? modalButton.save : modalButton.add}</button>
                </div>
            </form>
        );
    }
}
