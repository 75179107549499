import React, {Component} from 'react';
import Select from "../Core/Select";
import FilterService from "../../services/FilterService";
import ShowAlert from "../CoreAction/Notifications";

export default class BaseClass extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            showModal: false,
            openFilter: '',
            error: '',
            modalType: '',
            data_buffer: {},
            activeBranch: null,
            filterData: {},
            file_name: '',
            coordinate: {},
            sort: 1,
            sortField: '',
            ref: null,
            editType: '',
            form_error: [],
        };
        this.fcRef = React.createRef();
        this.container = React.createRef();
    }

    async componentDidMount() {
        await this.getData();
        await this.getFilter();
    }

    getFilter = async () => {
        this.setState({
            isLoading: true
        })
        try{
            const response = await FilterService.getFilter(this.state.components);
            if (response.data){
                this.confEdit(response.data);
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    confEdit = (data) => {
        let conf = this.state.confTable;
        Object.keys(conf).map(item => {
            if (data[item]){
                conf[item] = true;
            } else {
                conf[item] = false;
            }
        });
        this.setState({
            confTable: conf,
        })
    }

    addValue = async (value) => {
        console.log(value);
        this.setState((state) => {
            return {
                [this.state.components]: [...state[this.state.components], ...[value]],
                form_error: []
            };
        });

        ShowAlert('success', 'add', this.state.components);

        return true;
    }

    editValue = async (value) => {
        const newValue = this.state[this.state.components].slice();
        let index = this.state[this.state.components].findIndex(item => item.id === value.id);
        newValue[index] = value;
        newValue[index].number = index+1;
        this.setState((state) => {
            return {
                [this.state.components]: newValue,
                form_error: []
            };
        });

        ShowAlert('success', 'edit', this.state.components);

        return true;
    }

    deleteValue = async (id) => {
        let clone = this.state[this.state.components];

        let index = this.state[this.state.components].findIndex(item => item.id === id);

        const newArray = [
            ...clone.slice(0, index),
            ...clone.slice(index + 1)
        ];

        this.setState({
            [this.state.components]: newArray,
        });

        ShowAlert('error', 'delete', this.state.components);

        return true;
    }

    /* filter */

    openFilter = async (filter, ref) => {
        console.log(filter, this.state.openFilter)
        if (filter === this.state.openFilter){
            window.removeEventListener("resize", this.resize);
            document.getElementById('app').removeEventListener("click", this.removeFilter);
            document.querySelector('.container-inner').removeEventListener('scroll', this.removeFilter)
            this.setState({
                openFilter: '',
            })
        } else {
            let coordinate = await ref.current.getBoundingClientRect();
            document.getElementById('app').addEventListener("click", this.removeFilter);
            document.querySelector('.container-inner').addEventListener('scroll', this.removeFilter)
            window.addEventListener("resize", this.resize);
            this.setState({
                openFilter: filter,
                coordinate: {x: coordinate.x, y: coordinate.y},
                ref: ref.current,
            })
        }
    }

    removeFilter = (e) => {
        const parentElement = this.fcRef.current;
        let target = e.target;
        console.log(target.classList);
        if (!parentElement.contains(target) && !target.classList.contains('fa-filter')) {
            console.log('close');
            this.setState({
                openFilter: ''
            })
            document.getElementById('app').removeEventListener("click", this.removeFilter);
            document.querySelector('.container-inner').removeEventListener('scroll', this.removeFilter)
        }
    }
    calcCoordinateFilter = () => {
        let x = this.state.coordinate.x ;
        if (x){
            x -= 50;
            if (x - 260 > 0){
                x -= 260;
            } else {
                x -= 10;
            }
        }
        return {left: x, top: this.state.coordinate.y};
    }
    resize = async () => {
        let coordinate = this.state.ref.getBoundingClientRect();
        this.setState({
            coordinate: {x: coordinate.x, y: coordinate.y},
        })
    };

    setFilterValue = (event) => {
        let clone = this.state.filterData;

        const target = event.target;
        const value = target.value;
        const name = target.name;

        clone[name] = value;

        this.setState({
            filterData: clone
        })
    }

    setFilterArray = (value, name) => {
        let clone = this.state.filterData;
        clone[name] = value;

        this.setState({
            filterData: clone
        })
    }

    confirmFilter = (field) => {
        this.onFilter(field, this.state.filterData[field])
    };

    clearFilter = (e, field, id = null) => {
        this.onFilter(field, '', id)
    };

    renderFilter = (data, filterToField) => {
        let component = null;
        const searchField = filterToField ? Object.keys(filterToField) : [];

        if (searchField.indexOf(this.state.openFilter) !== -1){
            component =  <Select
                value={this.state.filterData?.[this.state.openFilter] ? this.state.filterData[this.state.openFilter]: []}
                filterValue={data[this.state.openFilter]}
                field={this.state.field[this.state.openFilter]}
                name={this.state.openFilter}
                onChange={this.setFilterArray}
                options={filterToField[this.state.openFilter]}
            />;
        } else {
            component =  <input type="text" className="form-control" value={this.state.filterData[this.state.openFilter] || ''} name={this.state.openFilter} autoComplete="off"
                                onChange={this.setFilterValue}/>;
        }


        return component;

    }

    /* filter */

    InputChangeText = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    onSort = sortField => {
        const sortType = this.state.sort === 1 ? -1 : 1;
        this.setState({
            sort: sortType,
            sortField
        })
    };

    onFilter = (field, filter, id) => {
        let clone = this.state.filter;
        if (id) {
            let index = clone[field].findIndex(item => item.id === id);
            clone[field] = [
                ...clone[field].slice(0, index),
                ...clone[field].slice(0, index + 1)
            ];
        } else {
            clone[field] = filter;
        }

        if (!filter){
            let cloneData = this.state.filterData;
            delete cloneData[field];
            this.setState({
                filterData: cloneData,
            })
        }

        this.setState({
            filter: clone,
            openFilter: '',
        })
    };

    handleModal = () => {
        this.setState({showModal: !this.state.showModal});
    };

    closeModal = () => {
        if (this.state.showModal) {
            document.getElementById('app').addEventListener("click", this.listener);
            document.body.style.overflow = 'hidden';
        } else {
            document.getElementById('app').removeEventListener("click", this.listener);
            document.body.style.overflow = '';
        }
    };

    listener = (e) => {
        const parentElement = document.getElementById('modal');
        let target = e.target;
        if (!parentElement.contains(target)) {
            this.handleModal()
        }
    };

    getHelperContainer = () => {
        return this.table.current.childNodes[1]
    };

    selectChange = (newValue, actionMeta) => {
        const value = newValue ? newValue.value : 0;
        this.setState({
            [actionMeta.name]: value
        });
    };

    addItem = () => {
        this.setState({showModal: !this.state.showModal, modalType: 'add', form_error: []});
    };

    editItem = (e, index) => {
        this.setState({
            showModal: !this.state.showModal,
            form_error: [],
            modalType: 'update',
            data_buffer: {element: this.state[this.state.components].find(item => item.id === index), index: index}
        });
    };

    deleteItem = (e, index) => {
        this.setState({
            showModal: !this.state.showModal,
            modalType: 'delete',
            data_buffer: {index: index},
            form_error: []
        });
    };

    getValueId = (data) => {
        let result = {};
        for (let i = 0; i < data.length; i++) {
            result[data[i].id] = data[i];
        }
        return result;

    };

    dataFromSelect = (data, name, active=[]) => {
        let result = [];
        for (let i = 0; i < data.length; i++) {
            result[i] = {value: data[i].id, label: data[i][name]};
        }
        return result;
    };

    InputChange = (e, name) => {
        e.preventDefault();
        e.stopPropagation();
        const newValue = this.state.confTable;
        newValue[name] = !newValue[name];
        this.setFilter(newValue);
    };

    setFilter = async (data) => {
        let send = {
            type: this.state.components,
            config: data
        }
        try{
            const response = await FilterService.setFilter(this.state.components, send);
            if (response){
                this.confEdit(data);
            }
            return response;
        } catch (e) {
            console.log(e)
        }
    }


    sort = (conf) => {
        let check = false;
        let clone = this.state[this.state.components].slice();
        let data = clone;
        if (Object.keys(conf).indexOf(this.state.sortField) !== -1) {
            data = clone.map((el, i) => {
                if (el[this.state.sortField] && Array.isArray(el[this.state.sortField])) {
                    return {
                        index: i,
                        [this.state.sortField]: el[this.state.sortField][0].staff_id && conf[this.state.sortField][el[this.state.sortField][0].staff_id] ? conf[this.state.sortField][el[this.state.sortField][0].staff_id][this.state.field[this.state.sortField]] : ''
                    };
                } else {
                    return {
                        index: i,
                        [this.state.sortField]: el[this.state.sortField] && conf[this.state.sortField][el[this.state.sortField]] ? conf[this.state.sortField][el[this.state.sortField]][this.state.field[this.state.sortField]] : ''
                    };
                }
            });
            check = !check;
        }

        data.sort((a, b) => {
            if (!a[this.state.sortField]) {
                return this.state.sort;
            }

            if (a[this.state.sortField] && !b[this.state.sortField]) {
                return -this.state.sort;
            }

            if (a[this.state.sortField] > b[this.state.sortField]) {
                return this.state.sort;
            }
            if (a[this.state.sortField] < b[this.state.sortField]) {
                return -this.state.sort;
            }
            return 0;
        });

        if (check) {
            return data.map((el) => {
                return clone[el.index];
            });
        } else {
            return data;
        }
    };

    quote = (regex) => {
        return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
    }

    filterBy = (conf) => {
        const arr = this.state.sortField ? this.sort(conf) : this.state?.[this.state.components] ?? [];
        return arr.filter((item) => {
            let conf = true;
            Object.keys(this.state.filter).map((filter) => {
                if ((this.state.filter[filter] && !Array.isArray(this.state.filter[filter])) || (Array.isArray(this.state.filter[filter]) && this.state.filter[filter].length)) {
                    if (Array.isArray(this.state.filter[filter])) {
                        if (this.state.filter[filter].indexOf(item[filter]) === -1) {
                            if (conf) {
                                conf = false;
                            }
                        }
                    }else{
                        if (String(item[filter]).search(new RegExp(this.quote(this.state.filter[filter]), 'i')) === -1) {
                            if (conf) {
                                conf = false;
                            }
                        }
                    }
                }
            });
            return conf;
        });

    };

    uniqueElement = () => {
        let unique = {};
        let fields = Object.keys(this.state.filter).filter(item => Array.isArray(this.state.filter[item]) );
        if (this.state?.[this.state.components]) {
            this.state[this.state.components].map((item) => {
                fields.map((field) => {
                    if (!unique[field]) {
                        unique[field] = [];
                    }

                    if (Array.isArray(item[field]) && field !== 'branches') {
                        item[field].map((element) => {
                            if (unique[field].indexOf(element) === -1) {
                                unique[field].push(element);
                            }
                        })
                    } else if (Array.isArray(item[field])) {
                        item[field].map((element) => {
                            if (unique[field].indexOf(element.staff_id) === -1) {
                                unique[field].push(element.staff_id);
                            }
                        })
                    } else if (typeof item[field] === 'object') {
                        for (const element in item[field]) {
                            if (unique[field].indexOf(element) === -1) {
                                unique[field].push(element);
                            }
                        }
                    } else {
                        if (unique[field].indexOf(item[field]) === -1) {
                            unique[field].push(item[field]);
                        }
                    }

                })
            });
        }
        return unique;
    };

}

