import React, {Component} from 'react';
import "./style.css"

export default class Date extends Component{

    constructor(props) {
        super(props);
        this.state = {
            months: '',
            days: '',
            hours: '',
            minutes: '',
        };
    }

    componentDidMount() {
        if (this.props.time){
            this.setBase(this.props.time);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.time !== prevProps.time){
            this.setBase(this.props.time);
        }
        if (JSON.stringify(prevState) !== JSON.stringify(this.state)){
            this.sendData();
        }
    }


    setBase = (time) => {

        let months = 0,
            days = 0,
            hours = 0,
            minutes = 0;

        if (time >= 30 * 24 * 60 * 60){
            months =  Math.floor(time/(30 * 24 * 60 * 60));
            time -= (months * 30 * 24 * 60 * 60);
        }

        if (time >= 24 * 60 * 60){
            days =  Math.floor(time/(24 * 60 * 60));
            time -= (days * 24 * 60 * 60);
        }

        if (time >= 60 * 60){
            hours =  Math.floor(time/(60 * 60));
            time -= (hours * 60 * 60);
        }


        if (time >= 60){
            minutes = time / 60;
        }

        this.setState({
            months,
            days,
            hours,
            minutes
        })
    }



    handleChange = (e) => {
        this.setState({
            [e.target.name]:Number(e.target.value)
        })
    };

    sendData = () => {
        let time = 0;
        if (this.state.months){
            time += this.state.months * 30 * 24 * 60 * 60;
        }

        if (this.state.days){
            time += this.state.days * 24 * 60 * 60;
        }

        if (this.state.hours){
            time += this.state.hours * 60 * 60;
        }

        if (this.state.minutes){
            time += this.state.minutes * 60;
        }

        this.props.updateCore(this.props.name, time);
    }

    render() {
        return (
            <div className='select-date'>
                <input type="number" name="months" onChange={this.handleChange} value={this.state.months || ''} className="form-control" min={0} placeholder='Мес'/>
                <input type="number" name="days" onChange={this.handleChange} value={this.state.days || ''} className="form-control" min={0} max={31} placeholder='Дни'/>
                <input type="number" name="hours" onChange={this.handleChange} value={this.state.hours || ''} className="form-control" min={0} max={24} placeholder='Часы'/>
                <input type="number" name="minutes" onChange={this.handleChange} value={this.state.minutes || ''} className="form-control" min={0} max={60} placeholder='Мин'/>
            </div>
        );
    }

};

