import React from 'react';
import "../login.css"
import Button from "./Button";

const Login = ({google, user, setGoogle, exit}) => {


    return (
        <div className="login-item">
            <div className="image sheets">
            </div>
            <div className="user-info">
                <div className="login">
                    <span className="table">{ google?.account?.table ?? 'Не подключено'} </span><br/>
                    <span className="page">{ google?.account?.page ?? ''}</span>
                </div>
                {
                    !google.token ? <Button user={user} setGoogle={setGoogle}/> :
                        <div className="exit">
                            <button className="btn btn-danger" onClick={(e) => exit('google')}>выйти</button>
                        </div>
                }

            </div>
        </div>
    );
};

export default Login;