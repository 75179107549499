import {field} from "../RU";
import React, {useRef} from "react";

const Th = (props) => {

    const filterRef = useRef(null);

    let active_class = "filter-table";

    if (props.filter[props.element] && !Array.isArray(props.filter[props.element])){
        active_class = "filter-table active";
    }

    if (props.filter[props.element] && Array.isArray(props.filter[props.element]) && props.filter[props.element].length){
        active_class = "filter-table active";
    }

    return (
        <div className="th-container">
            <div className="th-field-name">
                <div className="sort-table" onClick={props.onSort.bind(null, props.element)}>
                    {field[props.components][props.element]}
                    {props.sortField === props.element ?
                        <small>
                            {
                                props.sort === 1 ?
                                    <i className='fa fa-sort-amount-up-alt'></i> :
                                    <i className='fa fa-sort-amount-down'></i>
                            }
                        </small>
                        : null}
                </div>
                {
                    props.filter[props.element] && !Array.isArray(props.filter[props.element]) ?
                        <div className="active-filter" onClick={(e) => props.clearFilter(e, props.element)}>
                            {props.filter[props.element]}
                        </div> : null
                }
            </div>

            <div className={active_class} ref={filterRef} onClick={(e) => props.openFilter(props.element, filterRef)}>
                <i className='fa fa-filter'> </i>
            </div>
        </div>

    );
};

export default Th;
