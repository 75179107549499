import React, { useState } from 'react';
import "../login.css"
import Button from "./Button";

const Login = ({amo, user, setAmo, exit}) => {

    const [newUser, setNew] = useState(0);

    const newAuth = (e) => {
        if (e.target.checked){
            setNew(1);
        } else {
            setNew(0);
        }
    }

    return (
        <div className="login-item">
            <div className="image amo">
            </div>
            <div className="user-info">
                <div className="login">
                    <span className="table"> { amo?.account ?? 'Не подключено'} </span><br/>
                    {
                        !amo?.account ? <span className="page"><input type="checkbox" name='new' id='new' checked={newUser} onChange={newAuth}/> <label
                            htmlFor="new">Новый пользователь</label></span> : null

                    }

                </div>
                {
                    !amo.token ? <Button user={user} new={newUser} client_id={amo?.client_id ?? null} setAmo={setAmo}/> :
                        <div className="exit">
                            <button className="btn btn-danger" onClick={(e) => exit('amo')}>выйти</button>
                        </div>
                }

            </div>
        </div>
    );
};

export default Login;