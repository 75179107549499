import React, {Component} from 'react';
import './user.css';
import {
   Link,
} from "react-router-dom";

export default class User extends Component {

    render() {
        let userBar = 'user-bar';
        userBar += this.props.open_bar ? ' open' : '';

        let overley = 'default-overlay';
        overley += this.props.open_bar  ? ' visible' : '';

        return (
            <div>
                <div className='user-photo' onClick={this.props.openUserBar}>
                    <img src='/resources/avatar-none.png' alt=""/>
                </div>
                <div className={userBar}>
                    <div className="user-info">
                        <div>{this.props.user.name}</div>
                    </div>
                    <div className="user-profile">
                        <Link to="/edit" onClick={this.props.openUserBar}>
                            <i className="fa fa-pencil-alt"></i>
                            профиль
                        </Link>
                    </div>
                    <div className="user-logout" onClick={this.props.logout} >
                        <i className="fa fa-power-off"></i>
                        Выйти
                    </div>
                </div>
                <div id="left-menu-overlay" onClick={this.props.openUserBar} className={overley}>

                </div>
            </div>

        );
    }
}
