import React from 'react';
import Dot from "../Loader/Dot/Dot";


const Users = (props) => {

    const active = (name, id) => {

        if (props.userData && Array.isArray(props.userData) && props.userData.includes(id)) {
            return 'active';
        }
        return '';
    }

    return (
        <div className="conf-item">
            <div className="ci-header">{props.children}</div>
            {
                props.data ? <div className="users-select">
                    {
                        props.data.map(item => (
                            <div key={`amo-user-${item.id}`}
                                 className={`user-item change-item ${active(props.name, item.id)}`} data-id={item.id}
                                 data-name={props.name} onClick={props.cheng}>{item.name}</div>
                        ))
                    }
                </div> : null
            }
            {
                props.loading ? <Dot/> : null

            }

        </div>
    );
};

export default Users;