import React from 'react';
import {admin, active} from '../../RU'

const TableItem = (props) => {
    return (
        <tr>
            <td>{props.item.id}</td>
            {props.confTable.name ? <td>{props.item.name}</td> : null}
            {props.confTable.email ? <td>{props.item.email}</td> : null}
            {props.confTable.is_admin ? <td>{admin[props.item.is_admin].label}</td> : null}
            {props.confTable.is_active ? <td>{active[props.item.is_active].label}</td> : null}
            {props.confTable.action ? <td className='action-td'>
            <button className='btn btn-primary' onClick={(e) => props.editItem(e, props.item.id)}>
                <i className='fa fa-pencil-alt'></i>
            </button>
            <button className='btn btn-danger' onClick={(e) => props.deleteItem(e, props.item.id)}>
                <i className='fa fa-trash-alt'></i>
            </button>
            </td> : null}
        </tr>
    );
};

export default TableItem;
