import React from 'react';
import Loader from '../Loader/Loader';
import Table from "../Core/Table";
import AddItem from "./Action/AddItem";
import Confirm from "../CoreAction/Confirm"
import Modal from "../CoreAction/Modal";
import TableItem from "./Table/TableItem";
import BaseClass from "../Classes/BaseClass";
import UserService from "../../services/UserService";
import {admin, active} from "../RU";
import Control from "../Control/Control";



export default class Users extends BaseClass {

    constructor(props, state) {
        super(props, state);
        this.state.users = [];
        this.state.users_groups = [];
        this.state.confTable = {name: true, email: true, is_admin: true, is_active: true, action: true};
        this.state.filter = {name: '', email: '', is_admin: [], is_active: []};
        this.state.field = {is_admin: 'label', is_active: 'label'};
        this.state.form_error = [];
        this.state.components = 'users';
    }

    getData = async () => {
        this.setState({
            isLoading: true
        })
        try{
            const response = await UserService.getUsers();
            let setData = {};
            for(const item in response.data){
                setData[item] = response.data[item];
            }
            this.setState(setData);
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    add = async (data) => {
        try{
            const response = await UserService.addUser(data);
            if (response.data){
                await this.addValue(response.data);
            }
            return response?.data ?? null
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({
                showModal: !this.state.showModal,
            })
        }
    }

    update = async (data) => {
        try{
            const response = await UserService.updateUser(data);
            if (response.data){
                await this.editValue(response.data);
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({
                showModal: !this.state.showModal,
            })
        }
    }

    del = async (id) => {
        try{
            const response = await UserService.deleteUser(id);
            if (response.data){
                await this.deleteValue(id);
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({
                showModal: !this.state.showModal,
            })
        }
    }

    handleModal = () => {
        this.setState({showModal: !this.state.showModal});
    };


    elements = () => {
        switch (this.state.modalType) {
            case 'add':
                return <AddItem
                    addItem={this.add}
                    handleModal={this.handleModal}
                    form_error={this.state.form_error}
                    users_groups={this.state.users_groups}
                    components={this.state.components}
                    incidents={this.state.incidents}
                    data={{index: 0}}
                />
            case 'update':
                return <AddItem
                    addItem={this.update}
                    handleModal={this.handleModal}
                    form_error={this.state.form_error}
                    users_groups={this.state.users_groups}
                    components={this.state.components}
                    incidents={this.state.incidents}
                    data={this.state.data_buffer}
                />
            case 'delete':
                return <Confirm
                    confirm={(e) => this.del(this.state.data_buffer.index)}
                    components={this.state.components}
                    handleModal={this.handleModal}
                />
        }
    };

    render() {
        const tableUsersGroups = this.getValueId(this.state.users_groups);

        const users = this.filterBy( {is_admin: admin, is_active: active});
        const filterToField = this.uniqueElement();

        let position = this.calcCoordinateFilter();

        return (
            this.state.isLoading
                ? <Loader/> :
                <div className="page-container">
                    <Control
                        handleModal={this.handleModal}
                        addClass={` line-table users-table`}
                        confTable={this.state.confTable}
                        components={this.state.components}
                        addItem={this.addItem}
                        InputChange={this.InputChange}>
                    </Control>
                   <div className="container-inner">
                        <div ref={this.fcRef} className={`dropdown-menu table-filter ${this.state.openFilter ? 'show' : ''}`} style={position}>
                            {
                                this.state.openFilter ?
                                    <>
                                        {this.renderFilter({is_admin: admin,  is_active: active}, filterToField)}
                                        <button className="btn btn-info btn-sm w-100 btn-filter" onClick={(e) => this.confirmFilter(this.state.openFilter)}>фильтровать</button>
                                    </>
                                    : null
                            }
                        </div>
                       <Table confTable={this.state.confTable}
                              addClass={` line-table users-table`}
                              onSort={this.onSort}
                              openFilter={this.openFilter}
                              clearFilter={this.clearFilter}
                              sort={this.state.sort}
                              sortField={this.state.sortField}
                              field={this.state.field}
                              components={this.state.components}
                              filter={this.state.filter}>
                            {users.map((item, index) => (
                                <TableItem key={`item-${item.id}`}
                                           index={index}
                                           item={item}
                                           itemIndex={index}
                                           users_groups={tableUsersGroups}
                                           editItem={this.editItem}
                                           deleteItem={this.deleteItem}
                                           confTable={this.state.confTable}
                                />
                            ))
                            }
                        </Table>
                    </div>
                    {this.state.showModal ?
                        <Modal
                            handleModal={this.handleModal}
                            type={this.state.modalType}
                            components={this.state.components}
                        >
                            {this.elements()}
                        </Modal> : null
                    }
                </div>

        );
    }
}
