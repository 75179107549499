import React from 'react';

export default props => {
    return (
        <ul className='error-field'>
            {
                props.error.map((item, index) => (
                    <li key={`item-${index}`} className='error-field-item'>
                        {item}
                    </li>
                ))
            }
        </ul>
    )
}
