import React from 'react';
import {Dropdown} from "react-bootstrap";
import './control.css';
import {field, sidebar} from "../RU";

export default props => {
    return (
        <div className='product-control fixed-top-menu'>
            <div className="component-name">
                {sidebar[props.components]}
            </div>
            {props.children}

            {
                props.confTable ? <Dropdown role="menuitemcheckbox" drop={'down'} >
                    <Dropdown.Toggle variant="btn-primary">
                        Настройка полей
                    </Dropdown.Toggle>

                    <Dropdown.Menu rootCloseEvent='click'>
                        {Object.keys(props.confTable).map((item, index) => (
                            <Dropdown.Item  key={index} >
                                <div onClick={(e) => props.InputChange(e, item)}>
                                    <input type="checkbox"
                                           className='control-input'
                                           checked={props.confTable[item]}
                                           readOnly={true}
                                           name={item}
                                           id={item}/>
                                    <label htmlFor={item}>&nbsp;{field[props.components][item] ?? field[item] ?? ''}</label>
                                </div>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown> : null
            }

            {
                props.addItem ?
                    <button className="btn btn btn-primary" onClick={props.addItem}>
                        + Добавить Компонент
                    </button> : null
            }


        </div>
    )
}
