import React from 'react';
import Dot from "../Loader/Dot/Dot";


const Pipe = (props) => {

    const active = (name, pipe, id) => {

        if (props.userData?.[pipe] && Array.isArray(props.userData[pipe]) && props.userData[pipe].includes(id)) {
            return 'active';
        }
        return '';
    }

    return (
        <div className="conf-item">
            <div className="ci-header">{props.children}</div>

            {
                props.data ? <div className="users-select">
                    {
                        props.data.map(items => (
                            items?._embedded?.statuses?.map(item => (
                                <div style={{backgroundColor: item.color}} key={`amo-user-${item.id}`}
                                     className={`user-item change-item ${active(props.name, items.id, item.id)}`}
                                     data-pipeline={items.id} data-id={item.id} data-name={props.name}
                                     onClick={props.cheng}>{`${items.name} ${item.name}`}</div>
                            ))
                        ))
                    }
                </div> : null
            }

            {
                props.loading ? <Dot/> : null

            }

        </div>
    );
};

export default Pipe;