import React, {useReducer} from 'react';
import {field} from "../RU";
import Th from "./Th";
import {initialState, reducer} from "../CoreAction/filters";


const Table = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        dispatch({name, value});
    };

    const onChange = (value, name) => {
        dispatch({name, value:value});
    };

    const confirmFilter = (e, field) => {
        props.onFilter(field, state[field]);
    };

    const clearFilter = (e, field, id = null) => {
        dispatch({name:field, value:null});
        props.onFilter(field, '', id);
    };

    return (
        <table className={`table${!!props.addClass ? props.addClass : ''}`}>
            <thead>
            <tr>
                <th className="th-number tb-number">
                    <div className="sort-table" onClick={props.onSort.bind(null, 'number')}>
                        №
                        {props.sortField === 'number' ?
                            <small>{
                                props.sort === 1 ?
                                    <i className='fa fa-sort-amount-up-alt'></i> :
                                    <i className='fa fa-sort-amount-down'></i>
                                }
                            </small>
                            : null}
                    </div>
                </th>
                {
                    Object.keys(props.confTable).map((item, index) => (
                        props.confTable[item] ?
                            item !== 'action' ?
                                <th key={`th-${index}`} className={`tb-${item}`}>
                                    <Th element={item}
                                        clearFilter={props.clearFilter}
                                        filter={props.filter}
                                        sort={props.sort}
                                        sortField={props.sortField}
                                        dataFilter={props.dataFilter}
                                        field={props.field}
                                        components={props.components}
                                        openFilter={props.openFilter}
                                        onSort={props.onSort}/>

                                </th> :
                                <th key={`th-${index}`} className={`tb-${item}`}>{field.action}</th>
                            : null
                    ))
                }
            </tr>
            </thead>
            <tbody>
            {props.children}
            </tbody>
        </table>

    );

};

export default Table;
