import React from 'react';
import {modalButton, textConfirm} from "../RU";

const Confirm = (props) => {
    return (
        <div>
            <div className="modal-body">
                { props.text ? textConfirm[props.text] : textConfirm.default}
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" onClick={props.handleModal}>{modalButton.close}</button>
                <button className="btn btn-primary" onClick={props.confirm}>{textConfirm.textButtonConfirm}</button>
            </div>
        </div>
    );
};

export default Confirm;
