import './App.css';
import {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";


import Login from "./Auth/Login";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import axios from "axios";
import {API_URL} from "../Http";
import Loader from "./Loader/Loader";

import User from "./User/User";
import Users from "./Users/Users";
import Home from "./Home/Home";
import Edit from "./Edit/Edit";
import Widget from "./Widget/Widget";
import Sort from "./Sort/Sort";
import Make from "./Make/Make";

import Sidebar from "./Sidebar";
import {NotificationContainer} from "react-notifications";
import ShowAlert from "./CoreAction/Notifications";


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            open_bar: false,
            isAuth: false,
            user: {},
            components: 'base'
        };
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.checkAuth();
        }
    }

    login = async (login, password) => {
        try {
            const response = await AuthService.login(login, password);
            localStorage.setItem('token', response.data.accessToken);
            this.setState({
                isAuth: true,
                user: response.data.user
            })
        } catch (e) {
            console.log(e);
        }
    }

    logout = async () => {
        try {
            const response = await AuthService.logout();
            localStorage.removeItem('token');
            this.setState({
                isAuth: false,
                user: {},
                open_bar: false
            })
        } catch (e) {
            console.log(e);
        }
    }

    updateUser = async (data) => {
        try {
            const response = await UserService.editUser(data);
            if (response.data) {
                this.setState({
                    user: response.data
                })
                ShowAlert('success', 'save', this.state.components);
            }
        } catch (e) {
            console.log(e)
        }
    }


    setLoading = (check) => {
        this.setState({
            isLoading: check
        })
    }

    checkAuth = async () => {
        this.setLoading(true);
        try {
            const response = await axios.get(`${API_URL}/refresh`);
            localStorage.setItem('token', response.data.accessToken);
            this.setState({
                isAuth: true,
                user: response.data.user
            })
        } catch (e) {
            console.log(e);
        } finally {
            this.setLoading(false);
        }
    }

    openUserBar = () => {
        this.setState({open_bar: !this.state.open_bar});
    };

    render() {
        return (
            this.state.isLoading ? <Loader/> :
                <div className="App">
                    {
                        !this.state.isAuth ? <Login login={this.login}/> :
                            <Router>
                                <aside className="main-sidebar">
                                    <section className="sidebar">
                                        <div className="user-panel">
                                            <User user={this.state.user}
                                                  logout={this.logout}
                                                  openUserBar={this.openUserBar}
                                                  open_bar={this.state.open_bar}

                                            />
                                        </div>
                                        <div className="sidebar-menu-container">
                                            <Sidebar admin={this.state.user.is_admin}/>
                                        </div>
                                    </section>
                                </aside>

                                <Switch>
                                    {
                                        this.state.user.is_admin ?
                                            <Route path="/users"> <Users setLoading={this.setLoading}/></Route> : null
                                    }
                                    <Route path="/conf"> <Home setLoading={this.setLoading}
                                                               user={this.state.user}/></Route>
                                    <Route path="/make"> <Make setLoading={this.setLoading}
                                                               user={this.state.user}/></Route>
                                    <Route path="/sort"> <Sort setLoading={this.setLoading}
                                                               sort={this.state.user}/></Route>
                                    <Route path="/edit"> <Edit user={this.state.user}
                                                               updateUser={this.updateUser}/></Route>
                                    <Route path="/"><Widget user={this.state.user}/> </Route>
                                </Switch>
                                <NotificationContainer/>
                            </Router>
                    }
                </div>
        );
    }
}