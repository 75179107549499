import React, {Component} from "react";


export default class AddClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            disabled: false
        }
        this.form = React.createRef();
    }

    async componentDidMount() {
        this.baseData();
        document.addEventListener('keydown', this.sendForm);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.sendForm);
    }


    getActivePermission = (permission, active) =>{
        let activ = [];
        permission.map(item => {
            if (active.indexOf(item.value) !== -1){
                activ.push(item);
            }
        });

        return activ;
    }

    sendForm = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            this.form.current.click();
        }
    }

    handleChangeText = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    dataActiveSelect = (data, name, active) => {
        let result = [];
        let select = {};
        for (let i = 0; i < data.length; i++) {
            result[i] = {value: data[i].id, label: data[i][name]};
            if (data[i].id == active) {
                select = {value: data[i].id, label: data[i][name]};
            }
        }
        return {result, select: select.value ? select : null};
    };

    dataActive = (data, active) => {
        let select = {};
        data.map(item => {
            if (item.value === active){
                select = item
            }
        })
        return {result: data, select: select.value ? select : null};
    };

    handleChange = (newValue, actionMeta) => {
        const value = newValue ? newValue.value : '';

        this.setState({
            [actionMeta.name]: value,
        });
    };

    handleChangeData = (newValue, actionMeta) => {

        this.setState({
            [actionMeta.name]: newValue,
        });
    };

    orderOptions = values => {
        return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
    };

    onChangeMulti = (value, {action, removedValue, name}) => {
        switch (action) {
            case 'remove-value':
            case 'pop-value':
                if (removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                value = [];
                break;
        }
        if (value){
            value = value.map(item => item.value);
        }
        this.setState({ [name]: value });
    };

    dataFromSelect = (data, name) => {
        let result = [];
        for (let i = 0; i < data.length; i++) {
            let select_data = {value: data[i].id, label: data[i][name]};
            result.push(select_data) ;
        }
        return result;
    };

    dataOnSelect = (data, name, active) => {
        let result = [];
        let select = null;
        for (let i = 0; i < data.length; i++) {
            let select_data = {value: data[i].id, label: data[i][name]};
            result.push(select_data) ;
            if (data[i].id === active) {
                select = select_data;
            }
        }

        return {result, select};
    };

    dataCharts = (data, id, core_id) => {
        let result = [];
        let select = null;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id !== core_id) {
                result.push({value: data[i].id, label: data[i]['chart']});
                if (data[i].id === id) {
                    select = {value: data[i].id, label: data[i]['chart']};
                }
            }
        }
        return {result, select};
    };

    baseData = () => {
        if (this.props.data && this.props.data.element) {
            Object.keys(this.props.data.element).map(item => {
                this.setState({
                    [item]: this.props.data.element[item]
                });
            });
        }

        if(this.props.user_id){
            this.setState({
                user_id: this.props.user_id
            });
        }
    };

    handleSubmit = (event, field, field_add) => {

        this.setState({
            disabled: true
        })

        let error = {};

        field.map(item => {
            if (this.state[item] === '') {
                error[item] = true;
            }
        });

        if (Object.keys(error).length !== 0) {
            this.setState({
                error,
                disabled: false
            });
            event.preventDefault();
            event.stopPropagation();
        } else {
            let send = {}
            field_add.map(item => {
                send[item] = this.state[item];
            })
            this.props.addItem(send);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    getSelectData = (data, name, id) => {
        let result = [];
        let select = null;
        for (let i = 0; i < data.length; i++) {
            result.push({value: data[i].id, label: data[i][name]});
            if (data[i].id === id) {
                select = {value: data[i].id, label: data[i][name]};
            }

        }
        return {result, select};
    };

    getDataFromSelect = (data, name) => {
        let result = [];
        for (let i = 0; i < data.length; i++) {
            result.push({value: data[i].id, label: data[i][name]});
        }
        return result;
    };

    dataPosts = (data, departments_id, active, id) => {
        let result = [];
        let select = {};
        for (let i = 0; i < data.length; i++) {
            if (data[i].departments_id == departments_id && data[i].id !== id)
                result.push({value: data[i].id, label: data[i].post});
            if (data[i].id == active) {
                select = {value: data[i].id, label: data[i].post};
            }
        }

        return {result, select};
    };

    changeQuill = (value, name) => {
        this.setState({
            [name]: value
        });
    };

}
