import React from 'react';
import {admin, active} from '../../RU'

const TableItem = (props) => {
    return (
        <tr>
            <td>{props.item.id}</td>
            {props.confTable.name ? <td>{props.item.name}</td> : null}
            {props.confTable.text ? <td>{props.item.text}</td> : null}
            {props.confTable.action ? <td className='action-td'>
            <button className='btn btn-primary' onClick={(e) => props.editItem(e, props.item.id)}>
                <i className='fa fa-pencil-alt'></i>
            </button>
            </td> : null}
        </tr>
    );
};

export default TableItem;
