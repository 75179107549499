import React from 'react';


const Button = (props) => {
    const openWindow = () => {
        let url = createUrl();
        console.log(url);
        const w = 750;
        const h = 580;
        const dual_screen_left = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
        const dual_screen_top = window.screenTop !== undefined ? window.screenTop : window.screen.top;

        const width = window.innerWidth
            ? window.innerWidth
            : (document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width);

        const height = window.innerHeight
            ? window.innerHeight
            : (document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height);

        const left = ((width / 2) - (w / 2)) + dual_screen_left;
        const top = ((height / 2) - (h / 2)) + dual_screen_top;

        const new_window = window.open(url, 'Авторизация через amoCRM', 'scrollbars, status, resizable, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

        if (window.focus) {
            new_window.focus();
        }
        window.addEventListener('message', updateAmoInfo);

        function updateAmoInfo(e) {
            if (e.data){
                if (e.data.error !== undefined) {
                    alert(e.data.error);
                    window.removeEventListener('message', updateAmoInfo);
                    new_window.close();
                } else if(e.data.amo){
                    console.log(e.data.amo);
                    props.setAmo(e.data.amo);
                    window.removeEventListener('message', updateAmoInfo);
                    new_window.close();
                }
            }
        }
    }

    const createUrl = () => {
        let url = `https://www.amocrm.ru/oauth/?state=${props.user.hash}&mode=post_message`;
        if (props.client_id && !props.new){
            url += `&client_id=${props.client_id}`;
        }
        url += `&name=Инциденты&description=Инциденты&redirect_uri=https://incidents.7video.org/api/amo/auth`;
        url += `&secrets_uri=https://incidents.7video.org/api/amo/secrets&logo=https://incidents.7video.org/resources/logo_main.png&scopes[]=crm`
        return url;
    }
    return (
        <button className='btn btn-success' onClick={openWindow}>Подключить</button>
    );
};

export default Button;