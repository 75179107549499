import React,{useState} from "react";
import "./select.css"
import {filter} from "../RU";

const Select = (props) => {

    const [state, setState] = useState('');

    const setValue = (e, value) => {
        let values = props.value.slice();
        values.push(value);
        props.onChange(values, props.name);
    };

    const removeValue = (e, value) => {
        let values = props.value.slice();
        let index = values.indexOf(value);
        props.onChange([...values.slice(0, index), ...values.slice(index+1)], props.name);
    };

    const setAll = (e, conf) => {
        let mass = [];
        if(conf.length > 0){
            conf.map(item => {
                mass.push(item.id);
            })
        }
        props.onChange(mass, props.name);
    };

    const filterOptions = (event) => {
        setState(event.target.value);
    };

    const arrayFind = (arr, data, name) => {
        let result = [];
        arr.map(item => {
            if (data[item]){
                result.push({id:item, field:data[item][name]});
            }

        });
        return result;
    };

    const filterField = (arr, filters) => {
        if (arr && filters) {
        let reg = new RegExp(quote(filters), 'i');
        return arr.filter(item => {
            return item.field.search(reg) !== -1;
        });
        } else {
            return arr;
        }
    };

    function quote(regex) {
        return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
    }

    const options = filterField(arrayFind(props.options, props.filterValue, props.field), state);

    return (
        <div className="filter-select">
            <div className="filter-select-button">
                <button className="btn btn-outline-success" onClick={(e) => setAll(e, options)}>
                    {filter.all}
                </button>
                <button className="btn btn-outline-secondary" onClick={(e) => setAll(e, [])}>
                    {filter.clear}
                </button>
            </div>
            <div className="filter-select-search">
                <input type="text" className="form-control" placeholder={filter.placeholder} onChange={filterOptions} value={state}/>
            </div>
            <div className="filter-select-inner">
                {
                    options.map((item) => (
                        props.value.indexOf(item.id) !== -1?
                            <div key={`filter-${props.field}-${item.id}`} className="filter-select-item option-select" onClick={(e) => removeValue(e, item.id)}>
                                <div className="filter-select-item-content" >
                                    <div className="filter-select-item-checkbox">

                                    </div>
                                    {props.filterValue[item.id][props.field]}
                                </div>
                            </div> :
                            <div key={`filter-${props.field}-${item.id}`} className="filter-select-item" onClick={(e) => setValue(e, item.id)}>
                                <div className="filter-select-item-content" >
                                    <div className="filter-select-item-checkbox">

                                    </div>
                                    {props.filterValue[item.id][props.field]}
                                </div>
                            </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Select;
