import {SortableContainer} from 'react-sortable-hoc';
import SortableItem from './SortableItem';

const SortableList = SortableContainer(({items}) => {
    return (
        <ul className='sort-list'>
            {items.map((value, index) => (
                <SortableItem key={`item-${value.index}`} index={index}>
                    {value.name}
                </SortableItem>
            ))}
        </ul>
    );
});

export default SortableList;