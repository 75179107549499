import React from 'react';
import {field, modalButton} from "../../RU";
import AddClass from "../../Classes/AddClass";


export default class AddItem extends AddClass {
    constructor(props, state) {
        super(props, state);
        this.form = React.createRef();
    }

    render() {
        return (
            <form onSubmit={(e) => this.handleSubmit(e, [], ['id', 'text']) }>
                <div className="modal-body">
                    <div className="form-group">
                        <div><label htmlFor='name'>{field.make.name}:</label></div>
                        <div>{this.state.name}</div>
                    </div>

                    <div className="form-group">
                        <label htmlFor='text'>{field.make.text}</label>
                        <div><textarea name="text" id="text" onChange={this.handleChangeText} value={this.state?.text || ''}></textarea></div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type='button' className="btn btn-secondary" onClick={this.props.handleModal}>{modalButton.close}</button>
                    <button type="submit" ref={this.form} disabled={this.state.disabled}
                            className="btn btn-primary">{this.state.id ? modalButton.save : modalButton.add}</button>
                </div>
            </form>
        );
    }
}
