import React, {useEffect, useState} from 'react';
import Dot from "../Loader/Dot/Dot";
import Select from "react-select";


const Fields = (props) => {
    const buildValue = () => {
        let selectData = [];

        let data = props.select.find(option => option.value === props.item.field_id);

        data.enums.map(item => {
            selectData.push({value: item.id, label: item.value});
        });

        return selectData
    }

    return (
        <div className="conf-item">
            <div className="fields-body">
                <div className="users-select">
                    <Select
                        isSearchable
                        cacheOptions
                        isClearablepipeChange
                        placeholder='Выбрать'
                        name='field_id'
                        onChange={(item) => props.change(item.value, 'field_id', props.index)}
                        value={props.item.field_id ? props.select.find(option => option.value === props.item.field_id) : null }
                        options={props.select}
                    />
                </div>

            {
                props.item.field_id ?
                    <>
                        <span className='users-equal'>
                            =
                        </span>
                        <div className="users-select">
                            <Select
                                isSearchable
                                cacheOptions
                                isClearablepipeChange
                                placeholder='Выбрать'
                                name='field_value'
                                onChange={(item) => props.change(item.value, 'field_value', props.index)}
                                value={props.item.field_value ? buildValue().find(option => option.value === props.item.field_value) : null }
                                options={buildValue()}
                            />
                        </div>
                    </>
                : null
            }

                <button className='btn btn-danger' onClick={(e) => props.delete(props.index)}>
                    <i className='fa fa-trash-alt'></i>
                </button>
            </div>
            {
                props.loading ? <Dot/> : null

            }
        </div>
    );
};

export default Fields;