import React from 'react';
import Loader from '../Loader/Loader';
import Table from "../Core/Table";
import AddItem from "./Action/AddItem";
import Modal from "../CoreAction/Modal";
import TableItem from "./Table/TableItem";
import BaseClass from "../Classes/BaseClass";
import MakeService from "../../services/MakeService";
import Control from "../Control/Control";
import "./style.css";


export default class Make extends BaseClass {

    constructor(props, state) {
        super(props, state);
        this.state.confTable = {name: true, text: true, action: true};
        this.state.filter = {name: '', text: ''};
        this.state.field = {};
        this.state.form_error = [];
        this.state.components = 'make';
    }

    getData = async () => {
        try{
            const response = await MakeService.getMake();
            this.setState({
                make: response.data
            });
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    update = async (data) => {
        try{
            const response = await MakeService.editMake(data);
            if (response.data){
                await this.editValue(response.data);
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({
                showModal: !this.state.showModal,
            })
        }
    }

    handleModal = () => {
        this.setState({showModal: !this.state.showModal});
    };

    render() {
        const users = this.filterBy({});
        const filterToField = this.uniqueElement();

        let position = this.calcCoordinateFilter();

        return (
            this.state.isLoading
                ? <Loader/> :
                <div className="page-container">
                    <Control
                        handleModal={this.handleModal}
                        addClass={` line-table users-table`}
                        confTable={this.state.confTable}
                        components={this.state.components}
                        InputChange={this.InputChange}>
                    </Control>
                    <div className="container-inner">
                        <div ref={this.fcRef} className={`dropdown-menu table-filter ${this.state.openFilter ? 'show' : ''}`} style={position}>
                            {
                                this.state.openFilter ?
                                    <>
                                        {this.renderFilter({}, filterToField)}
                                        <button className="btn btn-info btn-sm w-100 btn-filter" onClick={(e) => this.confirmFilter(this.state.openFilter)}>фильтровать</button>
                                    </>
                                    : null
                            }
                        </div>
                        <Table confTable={this.state.confTable}
                               addClass={` line-table users-table`}
                               onSort={this.onSort}
                               openFilter={this.openFilter}
                               clearFilter={this.clearFilter}
                               sort={this.state.sort}
                               sortField={this.state.sortField}
                               field={this.state.field}
                               components={this.state.components}
                               filter={this.state.filter}>
                            {users.map((item, index) => (
                                <TableItem key={`item-${item.id}`}
                                           index={index}
                                           item={item}
                                           itemIndex={index}
                                           editItem={this.editItem}
                                           deleteItem={this.deleteItem}
                                           confTable={this.state.confTable}
                                />
                            ))
                            }
                        </Table>
                    </div>
                    {this.state.showModal ?
                        <Modal
                            handleModal={this.handleModal}
                            type={this.state.modalType}
                            components={this.state.components}
                        >
                            <AddItem
                                addItem={this.update}
                                handleModal={this.handleModal}
                                form_error={this.state.form_error}
                                components={this.state.components}
                                data={this.state.data_buffer}
                            />
                        </Modal> : null
                    }
                </div>

        );
    }
}
