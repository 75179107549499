import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import {notification} from "../RU";

export default function ShowAlert(type, message,  component) {
    switch (type) {
        case 'info':
            NotificationManager.info(component ? notification[component][message]: notification.error[message]);
            break;
        case 'success':
            NotificationManager.success(component ? notification[component][message]: notification.error[message]);
            break;
        case 'warning':
            NotificationManager.warning(component ? notification[component][message] : notification.error[message]);
            break;
        case 'error':
            NotificationManager.error(component ? notification[component][message]: notification.error[message]);
            break;
    }
}
