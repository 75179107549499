import React from 'react';
import BaseClass from "../Classes/BaseClass";
import Config from "./Config";
import "./style.css";
import AmoLogin from "./Login/Amo/Login";
import GoogleLogin from "./Login/Google/Login";

export default class Amo extends BaseClass {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    async componentDidMount() {

    }

    render() {
        return (
            <>
            <div className='cflogin-container'>
                <AmoLogin exit={this.props.exit} amo={this.props.amo} setAmo={this.props.setAmo} user={this.props.user}/>
                <GoogleLogin exit={this.props.exit} google={this.props.google} setGoogle={this.props.setGoogle} user={this.props.user}/>
            </div>
                {
                    this.props.amo.token ? <Config setData={this.props.setData} save={this.props.save}/> : null
                }

            </>
        );
    }
}
