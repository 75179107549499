import $api from '../Http/index'

export default class AmoService{
    static async check(){
        return await $api.get('/amo/check')
    }

    static async exit(){
        return await $api.post('/amo/exit')
    }

    static async getConfig(){
        return await $api.get('/amo/config')
    }

    static async getUsers(){
        return await $api.get('/amo/users')
    }

    static async setData(data){
        return await $api.patch('/amo', {data:data})
    }
}