import {modalTitle} from "../RU";
import React from "react";

const Modal = (props) => {
    return(
        <div>
            <div className="modal fade show" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" id='modal'>
                        <div className="modal-header">
                            <h5 className="modal-title">{modalTitle[props.components][props.type]}</h5>
                            <button className="close" onClick={props.handleModal}>
                                <span>&times;</span>
                            </button>
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default Modal;
