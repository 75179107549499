import React, {Component} from "react";
import "./login.css"

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };
    }

    change = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    sendLogin = () => {
        this.props.login(this.state.email, this.state.password);
    }

    render() {
        return(
            <div className="login-container">
                <div className='auth-form-container'>
                    <div className="login-head">
                        Вход
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input className='form-control' type="text" name='email' id='email' onChange={this.change} value={this.state.email}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Пароль</label>
                        <input className='form-control' type="password" name='password' onChange={this.change} value={this.state.password}/>
                    </div>
                    <div className="btn-block">
                        <button className='btn btn-warning' onClick={this.sendLogin}>Войти</button>
                    </div>
                </div>
            </div>
        );
    }
}
