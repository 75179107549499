export const initialState = {};

export function reducer(state, action) {
    return {...state, [action.name]: action.value};
}


export const sort = (data, field, sort, conf, confField) => {
    let check = false;
    let clone = data.slice();
    if (Object.keys(conf).indexOf(field) !== -1) {
        data = data.map((el, i) => {
            if (el[field] && Array.isArray(el[field])) {
                if (field === 'departments'){
                    return {
                        index: i,
                        [field]: el[field][0] && conf[field][el[field][0]] ? conf[field][el[field][0]][confField[field]] : ''
                    };
                } else {
                    return {
                        index: i,
                        [field]: el[field][0].staff_id && conf[field][el[field][0].staff_id] ? conf[field][el[field][0].staff_id][confField[field]] : ''
                    };
                }
            } else {
                return {index: i, [field]: el[field] && conf[field][el[field]] ? conf[field][el[field]][confField[field]] : ''};
            }
        });
        check = !check;
    }

    data.sort((a, b) => {
        if (!a[field]) {
            return sort;
        }

        if (a[field] && !b[field]) {
            return -sort;
        }

        if (a[field] > b[field]) {
            return sort;
        }
        if (a[field] < b[field]) {
            return -sort;
        }
        return 0;
    });

    if (check) {
        return data.map((el) => {
            return clone[el.index];
        });
    } else {
        return data;
    }
};

export const filterBy = (arr, filters) => {
        return arr.filter(function (item) {
            let conf = true;
            Object.keys(filters).map((filter) => {
                if ((filters[filter] && !Array.isArray(filters[filter])) || (Array.isArray(filters[filter]) && filters[filter].length)) {
                    if (item[filter] || item[filter] === 0) {
                        let reg = new RegExp(filters[filter], 'i');
                        if (Array.isArray(filters[filter]) && filter !== 'branches' && filter !== 'departments') {
                            if (filters[filter].indexOf(item[filter]) === -1) {
                                if (conf) {
                                    conf = false;
                                }
                            }
                        } else if (filter === 'branches') {
                            for (let i = 0; i < item.branches.length; i++) {
                                if (filters[filter].indexOf(item.branches[i].staff_id) !== -1) {
                                    conf = true;
                                    break;
                                } else {
                                    conf = false;
                                }
                            }
                        } else if (filter === 'departments') {
                            for (let i = 0; i < item.departments.length; i++) {
                                if (filters[filter].indexOf(item.departments[i]) !== -1) {
                                    conf = true;
                                    break;
                                } else {
                                    conf = false;
                                }
                            }
                        } else {
                            if (String(item[filter]).search(reg) === -1) {
                                if (conf) {
                                    conf = false;
                                }
                            }
                        }

                    } else {
                        conf = false;
                    }
                }
            });
            return conf;
        });

};
